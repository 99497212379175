import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { useEffect, useRef, useState } from 'react';

import { getColor, getRadii, getZIndex } from '@yoweb/ui/styles/utils/theme';
import { DEFAULT_VIDEO_MAX_WIDTH, VIDEO_CONTROLS_COLOR } from '@yoweb/ui/constants/media';

type VideoPlayerProps = {
  videoUrl: string;
  videoMaxWidth?: number;
  zIndex?: number;
  autoPlay?: boolean;
  hideVideo?: boolean;
  onDuration?: (duration: number) => void;
  testId?: string;
};

const VideoPlayer = ({
  videoUrl,
  videoMaxWidth = DEFAULT_VIDEO_MAX_WIDTH,
  autoPlay = true,
  hideVideo = false,
  zIndex,
  onDuration,
  testId = 'video-player',
}: VideoPlayerProps) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [playerIsReady, setPlayerIsReady] = useState(false);

  useEffect(() => {
    if (onDuration && playerIsReady && playerRef.current) {
      playerRef.current.getInternalPlayer().getDuration().then(onDuration);
    }
  }, [onDuration, playerIsReady, playerRef]);

  return (
    <VideoSection $zIndex={zIndex} $hideVideo={hideVideo} data-testid={testId}>
      <ReactPlayer
        ref={playerRef}
        style={{ position: 'absolute', top: 0, left: 0 }}
        controls
        playing={!hideVideo}
        onReady={() => setPlayerIsReady(true)}
        url={videoUrl}
        width="100%"
        height="100%"
        maxWidth={videoMaxWidth}
        onDuration={onDuration}
        config={{
          vimeo: {
            playerOptions: {
              color: VIDEO_CONTROLS_COLOR,
              responsive: true,
              transparent: false,
              autoplay: autoPlay,
            },
          },
        }}
      />
    </VideoSection>
  );
};

const VideoSection = styled.div<{ $zIndex?: number; $hideVideo: boolean }>`
  border-radius: ${getRadii('large')};
  background: ${getColor('base1000')};
  position: relative;
  top: 0;
  left: 0;
  z-index: ${({ $zIndex }) => $zIndex ?? getZIndex('modal')};
  padding-top: 56.25%;
  display: ${({ $hideVideo }) => ($hideVideo ? 'none' : 'block')};

  iframe {
    border-radius: ${getRadii('large')};
  }
`;

export default VideoPlayer;
