import { merge } from 'lodash-es';

import { Modal, createStyle } from '@yoweb/ui/components/Modal/Modal';
import type { ModalMode } from '@yoweb/ui/components/Modal';
import VideoPlayer from './VideoPlayer';
import { DEFAULT_VIDEO_MAX_WIDTH } from '@yoweb/ui/constants/media';

type VideoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDuration?: (duration: number) => void;
  videoUrl: string;
  videoMaxWidth?: number;
  mode?: ModalMode;
  autoPlay?: boolean;
  /** Optional id for tests */
  testId?: string;
  className?: string;
};

export const VideoModal = ({
  isOpen,
  videoUrl,
  videoMaxWidth = DEFAULT_VIDEO_MAX_WIDTH,
  onClose,
  onDuration,
  mode = 'dark',
  testId = 'video-modal',
  autoPlay = true,
  className,
}: VideoModalProps) => {
  const style = merge(createStyle(), { content: { overflow: 'hidden' } });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={videoMaxWidth}
      mode={mode}
      style={style}
      hasOwnContent
      borderless
      hasCloseButton
      closeButtonInOverlay
      testId={testId}
      className={className}
    >
      <VideoPlayer
        videoUrl={videoUrl}
        autoPlay={autoPlay}
        videoMaxWidth={videoMaxWidth}
        zIndex={0}
        onDuration={onDuration}
      />
    </Modal>
  );
};

export default VideoModal;
