import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type DividerSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './DividerSliceTypes';
import { PrismicDivider } from '@yoweb/prismic-slicemachine/components/PrismicDivider';

type DividerSliceProps = {
  slice: DividerSliceResponse;
};

const DividerSlice = ({ slice }: DividerSliceProps) => {
  const { spacing, spacingTablet, spacingMobile } = slice.primary;

  const tabletSpace = spacingTablet ? spacingTablet : spacing;

  return (
    <PrismicDivider
      spacing={spacing ?? 0}
      spacingTablet={tabletSpace ?? 0}
      spacingMobile={spacingMobile ?? 0}
    />
  );
};

export default withPrismicGuardian(DividerSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
