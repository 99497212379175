import styled, { css } from 'styled-components';

import { Title } from '@yoweb/ui/components/typography';
import { media } from '@yoweb/ui/styles/utils';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { maxWidthMixin } from '@yoweb/ui/styles/utils/mixins.utils';
import type { ModalProps } from '@yoweb/ui/components/Modal/Modal';

export type ModalContentProps = Pick<
  ModalProps,
  'title' | 'children' | 'hasCloseButton' | 'noPaddingBottom' | 'maxWidth'
>;

/**
 * Default Modal Content
 */
const ModalContent = ({
  children,
  hasCloseButton,
  title,
  noPaddingBottom,
  maxWidth,
}: ModalContentProps) => (
  <Container noPaddingBottom={noPaddingBottom} maxWidth={maxWidth}>
    {title && (
      <Header hasCloseButton={hasCloseButton}>
        <Title size="sm" as="h1">
          {title}
        </Title>
      </Header>
    )}
    {children}
  </Container>
);

const Container = styled.div<Pick<ModalProps, 'noPaddingBottom' | 'hasCloseButton' | 'maxWidth'>>`
  padding: ${getSpace('normal3')};
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  ${maxWidthMixin};

  ${({ noPaddingBottom, hasCloseButton }) =>
    media.md`
    
      padding: ${getSpace(hasCloseButton ? 'large1' : 'normal3')} ${getSpace('normal3')} ${
        noPaddingBottom ? 0 : getSpace('normal3')
      };
  `}
`;

const Header = styled.header<Pick<ModalContentProps, 'hasCloseButton'>>`
  margin-bottom: ${getSpace('normal3')};
  max-width: 90%;

  ${({ hasCloseButton }) =>
    hasCloseButton &&
    css`
      margin-top: ${getSpace('normal3')};
    `}

  ${media.md`
    margin-top: 0;
  `}
`;

export default ModalContent;
