import styled, { css } from 'styled-components';

import { pxToRem, media } from '@yoweb/ui/styles/utils';

export type Spacing =
  | 0
  | 2
  | 4
  | 8
  | 12
  | 16
  | 24
  | 32
  | 40
  | 48
  | 56
  | 64
  | 72
  | 80
  | 96
  | 112
  | 128
  | 160
  | 192;

export type PrismicDividerProps = {
  spacing: Spacing;
  spacingTablet: Spacing;
  spacingMobile: Spacing;
};

export const PrismicDivider = styled.div.attrs({ role: 'separator' })<PrismicDividerProps>`
  ${({ spacingMobile }) => css`
    height: ${pxToRem(spacingMobile)};
  `};

  ${({ spacingTablet }) =>
    media.md`
        height: ${pxToRem(spacingTablet)};
      `};

  ${({ spacing }) =>
    media.lg`
        height: ${pxToRem(spacing)};
      `};
`;
