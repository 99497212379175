import { transparentize } from 'polished';
import { useState, type ReactNode, useEffect } from 'react';
import ReactModal, { type Styles, type Aria } from 'react-modal';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { cloneDeep } from 'lodash-es';

import { useTranslation } from '@yoweb/next-i18next';
import { theme } from '@yoweb/ui/styles';
import { breakpoints, media } from '@yoweb/ui/styles/utils';
import { getColor, getRadii, getSpace, getZIndex } from '@yoweb/ui/styles/utils/theme';
import { isServerRendered } from '@yoweb/utils/isServerRendered';
import { ButtonIcon } from '@yoweb/ui/components/buttons/ButtonIcon';
import { ModalContent } from '@yoweb/ui/components/Modal';
import { CloseIcon } from '@yoweb/ui/components/Icon/CloseIcon';
import { Box } from '@yoweb/ui/components/Box';
import { Divider } from '@yoweb/ui/components/Divider';
import { type MaxWidth, maxWidthMixin } from '@yoweb/ui/styles/utils/mixins.utils';
import { Grid } from '@yoweb/ui/components/Grid';

if (
  isServerRendered === false &&
  process.env.NODE_ENV !== 'test' &&
  document.getElementById('__next')
) {
  ReactModal.setAppElement('#__next');
}

export type ModalMode = 'dark' | 'light';

const MODAL_MAX_WIDTH = 578;
// TODO - DS has the default at 656. May want to use this as the default width.
export const MODAL_MAX_WIDTH_WIDE = 656;

export type ModalProps = {
  /* Boolean indicating if pressing the esc key should close the modal */
  shouldCloseOnEsc?: boolean;

  /** an element or array of elements for the modal bottom actions (note: render order is "right to left") */
  actionButtons?: JSX.Element | JSX.Element[];

  /** good for images/videos */
  borderless?: boolean;

  /** shows divider underneath modal header, defaults to true */
  showDivider?: boolean;

  /** good for images/videos */
  mode?: ModalMode;

  /** a label for how the content should be announced to screen readers */
  title?: string;

  /** the content of the modal */
  children: ReactNode;

  /** classname for the modal wrapper */
  className?: string;

  /** whether the modal should be open or closed */
  isOpen?: boolean;

  /** a callback that gets called when the modal is closed */
  onClose?: () => void;

  /** whether the modal should have a close button or not */
  hasCloseButton?: boolean;

  /** whether the close button should be positioned in the corner of the overlay instead of the content */
  closeButtonInOverlay?: boolean;

  /** Optional id for tests */
  testId?: string;

  /* Boolean indicating if the modal should be focused after render */
  shouldFocusAfterRender?: boolean;

  /* Whether modal has it's own content, i.e. no header or title. */
  hasOwnContent?: boolean;

  /* Whether modal has padding-bottom or not. */
  noPaddingBottom?: boolean;

  /* Whether modal fills out entire screen in mobile, defaults to true */
  fullScreenMobile?: boolean;

  /* Whether modal footer has border in mobile view, defaults to false */
  noFooterBorderTopMobile?: boolean;

  /* Whether the maxWidth on the Modal is set on the ModalContent, defaults to true */
  shouldMaxWidthContent?: boolean;

  /* Prevent adding no-scroll class to body. Prevents interfering with other no-scroll when there are multiple modals */
  disableNoScroll?: boolean;

  /* Aria values for modal */
  aria?: Aria;

  /* The styles object to extend from */
  style?: Styles;

  /* A function that returns a reference to the overlay element of the modal. */
  overlayRef?: (element: HTMLElement) => void;

  /* Id for Google analytics purpose */
  'data-gaid'?: string;
} & MaxWidth &
  DataTestId;

const getHeight = (): number | null => {
  if (!(typeof window !== 'undefined' && typeof document !== 'undefined')) return null;
  return window.innerHeight;
};

export const createStyle = (): Styles => ({
  /* stylelint-disable-next-line selector-type-no-unknown */
  overlay: {
    background: transparentize(0.4, theme.colors.base400),
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
    backdropFilter: 'blur(4px)',
    zIndex: theme.zIndices.modal,
  },

  content: {
    background: 'none',
    border: 'none',
    borderRadius: 0,
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    margin: 'auto',
    padding: 0,
    position: 'static',
    width: '100%',
  },
});

const transitionEnterTime = 400;
const transitionExitTime = 200;
export const modalAnimationVariants = {
  initial: {
    opacity: 0,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: transitionEnterTime / 1000,
      type: 'spring',
    },
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    transition: {
      duration: transitionExitTime / 1000,
    },
  },
} as const;

/**
 * A general modal component.
 */
export const Modal = ({
  mode = 'light',
  actionButtons,
  borderless = false,
  showDivider = true,
  children,
  className = '',
  fullScreenMobile = true,
  hasCloseButton,
  closeButtonInOverlay = false,
  hasOwnContent = false,
  isOpen,
  maxWidth = MODAL_MAX_WIDTH,
  noFooterBorderTopMobile = false,
  noPaddingBottom = false,
  onClose,
  shouldFocusAfterRender = undefined,
  shouldCloseOnEsc = true,
  shouldMaxWidthContent = true,
  disableNoScroll = false,
  testId,
  title,
  aria,
  style,
  overlayRef,
  ...props
}: ModalProps) => {
  const { t } = useTranslation<'common'>();
  const [isModalOpen, setIsModalOpen] = useState(Boolean(isOpen));
  const [height, setHeight] = useState<number | null>(getHeight);

  const [isFirstRender, setsIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setsIsFirstRender(false);
      return;
    }

    const setNextHeight = () => {
      const nextHeight = getHeight();
      setHeight(nextHeight);
    };

    setNextHeight();
    window.addEventListener('resize', setNextHeight, { passive: true });
    return () => window.removeEventListener('resize', setNextHeight);
  }, [isFirstRender]);

  const onModalClose = () => {
    onClose ? onClose() : setIsModalOpen(false);
  };

  useEffect(() => {
    // It seems incorrect that we're removing the ReactModal
    // just for the sake of the AnimatePresence animation.
    // This modal expects to be shown/hidden based on its isOpen prop.
    // For some reason on React v18, it stopped cleaning up its bodyOpenClassName
    // when removed from the DOM.
    if (!disableNoScroll) {
      if (!isOpen) {
        setTimeout(() => document.body.classList.remove('no-scroll'), transitionExitTime);
      } else {
        // was considering using transitionEnterTime here with a setTimeout,
        // but it looks better without it
        document.body.classList.add('no-scroll');
      }
    }

    if (isModalOpen !== undefined) {
      setIsModalOpen(Boolean(isOpen));
    }

    // in case this component gets removed from the DOM instead of using the isOpen prop
    return () => (disableNoScroll ? undefined : document.body.classList.remove('no-scroll'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const mergedStyle = style ? cloneDeep(style) : createStyle();

  if (mode === 'dark') {
    mergedStyle.overlay = {
      ...mergedStyle.overlay,
      background: transparentize(0.1, theme.colors.base900),
    };
  }

  if (mergedStyle?.content) {
    mergedStyle.content.minHeight = height ? `${height}px` : '100vh';
  }

  return (
    <AnimatePresence mode="wait">
      {isModalOpen && (
        <ReactModal
          contentLabel={title}
          isOpen={isModalOpen}
          onRequestClose={onModalClose}
          shouldFocusAfterRender={shouldFocusAfterRender}
          style={mergedStyle}
          testId={testId}
          shouldCloseOnEsc={shouldCloseOnEsc}
          aria={aria}
          shouldCloseOnOverlayClick
          overlayRef={(node) => overlayRef?.(node)}
        >
          {hasCloseButton && closeButtonInOverlay && (
            <CloseButton
              aria-label={t('close')}
              data-testid="modal-close-btn"
              onClick={onClose}
              variant="tertiary"
              icon={<CloseIcon />}
              size="lg"
              isFloating={false}
              mode={mode}
              radius="full"
            />
          )}
          <Wrap
            borderless={borderless}
            className={className}
            fullScreenMobile={fullScreenMobile}
            maxWidth={maxWidth}
            data-testid={props['data-testid']}
            data-gaid={props['data-gaid']}
          >
            <Aside
              animate="animate"
              exit="exit"
              initial="initial"
              variants={modalAnimationVariants}
              data-testid="modal-animation-container"
            >
              {hasCloseButton && !closeButtonInOverlay && !borderless && (
                <Box position="relative" height="large1">
                  <CloseButton
                    aria-label={t('close')}
                    data-testid="modal-close-btn"
                    onClick={onClose}
                    variant="tertiary"
                    icon={<CloseIcon />}
                    size="lg"
                    isFloating={false}
                    mode={mode}
                    radius="full"
                  />
                  <Box position="absolute" bottom={0} width="100%">
                    {showDivider && <Divider variant="tertiary" />}
                  </Box>
                </Box>
              )}
              {hasCloseButton && !closeButtonInOverlay && borderless && (
                <CloseButton
                  aria-label={t('close')}
                  data-testid="modal-close-btn"
                  onClick={onClose}
                  variant="tertiary"
                  icon={<CloseIcon />}
                  size="lg"
                  isFloating={false}
                  mode={mode}
                  radius="full"
                />
              )}

              {hasOwnContent ? (
                children
              ) : (
                <ModalContent
                  title={title}
                  hasCloseButton={hasCloseButton}
                  noPaddingBottom={noPaddingBottom}
                  maxWidth={shouldMaxWidthContent ? maxWidth : undefined}
                >
                  {children}
                </ModalContent>
              )}

              {actionButtons && (
                <ModalFooter noBorderTopMobile={noFooterBorderTopMobile}>
                  {actionButtons}
                </ModalFooter>
              )}
            </Aside>
          </Wrap>
        </ReactModal>
      )}
    </AnimatePresence>
  );
};

type WrapProps = {
  borderless: boolean;
  fullScreenMobile: boolean;
} & MaxWidth;

const Wrap = styled.div<WrapProps>`
  border-radius: ${getRadii('large')};
  flex: 1;
  margin-top: ${getSpace('normal1')};
  position: relative;
  ${maxWidthMixin};

  ${({ borderless }) => {
    const bgColor = borderless ? 'transparent' : getColor('base000');

    return css`
      background-color: ${bgColor};
    `;
  }}

  ${({ fullScreenMobile }) =>
    fullScreenMobile &&
    css`
      border-radius: 0;
      margin-top: 0;

      ${media.md`
        border-radius: ${getRadii('large')};
        margin-top: ${getSpace('normal1')};
      `}

      @media screen and (max-width: ${breakpoints.md}px) {
        max-width: 100%;
      }
    `}

  ${media.md`
    align-self: center;
    margin-top: 0;
  `}
`;

const Aside = styled(motion.aside)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled(ButtonIcon)`
  && {
    position: absolute;
    right: ${getSpace('small2')};
    top: ${getSpace('small2')};
    z-index: ${getZIndex('modal')};
    ${media.md`
      right: ${getSpace('normal1')};
      top: ${getSpace('normal1')};
    `}
  }
`;

interface ModalFooterProps {
  escapeContainer?: boolean;
  noBorderTopMobile?: boolean;
}

export const ModalFooter = styled.div<ModalFooterProps>`
  border-top: 1px solid ${getColor('base100')};
  display: flex;
  flex-direction: column;
  padding: ${getSpace('normal1')} ${getSpace('normal3')};

  ${media.md`
    flex-direction: row-reverse;
  `}

  ${({ escapeContainer }) =>
    escapeContainer &&
    css`
      margin-top: ${getSpace('normal3')};
      margin-left: -${getSpace('normal3')};
      margin-right: -${getSpace('normal3')};
    `}

  ${({ noBorderTopMobile }) =>
    noBorderTopMobile &&
    css`
      border-top: 0;

      ${media.md`
        border-top: 1px solid ${getColor('base100')};
      `}
    `}
`;

export const ModalActionButtons = styled(Grid)`
  && {
    grid-template-columns: 1fr;
    ${media.md`
      grid-template-columns: max-content max-content;
    `}
  }
`;
