import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const PlayIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.63 4.495a1.67 1.67 0 0 0-1.797.035C6.33 4.86 6 5.443 6 6.115v11.77c0 .672.33 1.256.833 1.585a1.67 1.67 0 0 0 1.796.035l9.78-5.885c.614-.368.891-1.02.891-1.62 0-.6-.277-1.251-.89-1.62L8.63 4.495Z"
      fill="currentColor"
    />
  </IconBase>
);

export default PlayIcon;
