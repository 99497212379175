import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';
import type { Spacing } from '@yoweb/prismic-slicemachine/components/PrismicDivider';

export const DividerSliceType = 'divider_slice';

// Required fields in slice
export const requiredPrimaryFields = [] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  spacing?: Spacing;
  spacingMobile?: Spacing;
  spacingTablet?: Spacing;
};

export type DividerSliceResponse = PrismicSlice<
  typeof DividerSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
