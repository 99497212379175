import styled from 'styled-components';

import { theme } from '@yoweb/ui/styles';
import { type MaxWidth, maxWidthMixin } from '@yoweb/ui/styles/utils/mixins.utils';

const SIZES = {
  sm: 1,
  ms: 1.5,
  md: 2,
};

export type DividerProps = {
  size?: keyof typeof SIZES;
  variant?: keyof typeof colors;
} & MaxWidth;

const colors = {
  primary: theme.colors.base900,
  secondary: theme.colors.base400,
  tertiary: theme.colors.base100,
  muted: theme.colors.base700,
  sandMedium: theme.colors.sandMedium,
} as const;

export const Divider = styled.hr<DividerProps>`
  background: ${({ variant = 'primary' }) => colors[variant]};
  border: none;
  height: ${({ size = 'sm' }) => `${SIZES[size]}px`};
  margin: 0;
  width: 100%;

  ${maxWidthMixin}
`;
